



































































































































































import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      dialog: false,
      title: 'Contact',
      valid: false,
      firstName: '',
      surname: '',
      nameRules: [(v) => !!v || 'Name is required'],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      phoneNumber: '',
      subject: '',
      subjectRules: [(v) => !!v || 'Subject is required'],
      message: '',
      messageRules: [(v) => !!v || 'Message is required'],
    };
  },
  methods: {
  },
});
