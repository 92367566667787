
































































import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {};
  },
});
