















import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      viewportHeight: 0,
    };
  },
  mounted() {
    this.viewportHeight = this.$vuetify.breakpoint.height;
  },
  methods: {
    imageUrl(): string {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return '/banner/banner.jpg';
        case 'lg':
        case 'xl':
        default:
          return '/banner/banner-desktop.jpg';
      }
    },
  },
});
