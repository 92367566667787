



















































import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      images: [
        '/gallery/gallery1.jpg',
        '/gallery/gallery2.jpg',
        '/gallery/gallery3.jpg'
      ]
    };
  },
  methods: {
    getImageGroups(groupSize: number): Array<[string, string]> {
      const groups = [];

      let i, j;

      for (i = 0, j = this.images.length; i < j; i += groupSize) {
        groups.push(this.images.slice(i, i + groupSize));
      }

      return groups;
    }
  }
});
