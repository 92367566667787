var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"shade1 pt-0 pt-lg-15",attrs:{"id":"testimonials"}},[_c('v-col',{staticClass:"pa-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"align":"center"}},[_c('v-lazy',{staticClass:"fill-height",attrs:{"options":{
              threshold: 0.5
            },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('h2',{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v(" Testimonials ")])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"hidden-md-and-down",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.testimonials),function(testimonial,testimonialIndex){return _c('v-col',{key:testimonialIndex,staticClass:"pt-0"},[_c('v-lazy',{attrs:{"options":{
                    threshold: 0.5
                  }}},[_c('transition',{attrs:{"name":"fade"}},[_c('v-card',{staticClass:"speech-bubble mb-10 pb-0"},[_c('v-card-text',{staticClass:"pb-0 px-2 px-lg-5 pt-1 pt-lg-3 pb-lg-1"},[_c('p',{staticClass:"\n                            text-subtitle-1 text-lg-body-1\n                            font-weight-bold\n                            justify-center\n                            white--text\n                          "},[_vm._v(" "+_vm._s(testimonial.content)+" ")])]),_c('v-card-text',{staticClass:"\n                          testimonial-name\n                          text-right\n                          pr-1\n                          pb-0\n                          pt-0\n                          shade11--text\n                        "})],1)],1)],1),_c('v-lazy',{attrs:{"options":{
                    threshold: 0.5
                  }}},[_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('div',{staticClass:"\n                          testimonial-name\n                          text-right\n                          pr-16\n                          pb-5\n                          pt-0\n                          shade11--text\n                        "},[_vm._v(" "+_vm._s(testimonial.name)+" ")])])])],1)],1)}),1)],1),_c('v-carousel',{staticClass:"hidden-lg-and-up",attrs:{"id":"carousel","hide-delimiter-background":"","show-arrows-on-hover":"","cycle":"","interval":"20000","height":"auto"},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"opacity":"30%"},attrs:{"fab":"","color":"black","small":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'arrow-left' },"size":"2x"}})],1)]}},{key:"next",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"opacity":"30%"},attrs:{"fab":"","color":"black","small":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":{ prefix: 'fas', iconName: 'arrow-right' },"size":"2x"}})],1)]}}])},_vm._l((_vm.getTestimonials()),function(testimonialGroup,testimonialIndex){return _c('v-carousel-item',{key:testimonialIndex,staticClass:"px-0 px-lg-15 pb-10",attrs:{"eager":""}},[_c('v-row',_vm._l((testimonialGroup),function(testimonial,testimonialIndex){return _c('v-col',{key:testimonialIndex},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-lazy',{staticClass:"fill-height",attrs:{"options":{
                            threshold: 0.5
                          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('v-card',{staticClass:"speech-bubble mb-10"},[_c('v-card-text',{staticClass:"pb-0 px-2 px-lg-5 pt-1 pt-lg-3 pb-lg-1"},[_c('p',{staticClass:"\n                                    text-subtitle-1 text-lg-body-1\n                                    font-weight-bold\n                                    justify-center\n                                    white--text\n                                  "},[_vm._v(" "+_vm._s(testimonial.content)+" ")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-lazy',{staticClass:"fill-height",attrs:{"options":{
                            threshold: 0.5
                          },"align":"center"}},[_c('transition',{attrs:{"name":"fade"}},[_c('p',{staticClass:"\n                                testimonial-name\n                                text-right\n                                pr-1\n                                pb-5\n                                pt-0\n                                shade11--text\n                              "},[_vm._v(" "+_vm._s(testimonial.name)+" ")])])],1)],1)],1)],1)],1)}),1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }