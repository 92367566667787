export function getGroups(
  array: Array<Object>,
  groupSize: number,
): Array<Array<Object>> {
  const groups = [];

  let i; let
    j;

  for (i = 0, j = array.length; i < j; i += groupSize) {
    groups.push(array.slice(i, i + groupSize));
  }

  return groups;
}
