



























































import Vue from 'vue';

export default Vue.extend({
  components: {},
  watch: {},
  data() {
    return {
      header: 'TONY HAYWOOD',
      content:
        'Tony Haywood is a highly experienced singer/acoustic guitarist creating\n'
        + 'the perfect soundscape for your wedding day. Whether it is to welcome\n'
        + 'your guests as they arrive, for drinks on the lawn or to accompany your\n'
        + 'wedding breakfast the set can be tailored to your own unique\n'
        + 'requirements.\n'
        + '<br><br>'
        + 'With an extensive repertoire comprising of a varied range of styles\n'
        + 'encompassing all age groups he is also well suited to provide\n'
        + 'entertainment for functions such as Corporate events, Anniversaries,\n'
        + "Birthdays and BBQ's.",
    };
  },
  methods: {},
});
